'use client';
import { BusinessPartnerPlanIcon } from '@/components/common/icons/businessPartnerPlanIcon.icon';
import useClickOutside from '@/hooks/useClickOutside';
import useLinks from '@/hooks/useLinks';
import { useTranslation } from '@/i18n/client';
import Image from 'next/image';
import Link from 'next/link';
import { useParams, usePathname, useRouter } from 'next/navigation';
import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import {
  BiArrowBack,
  BiCaretDown,
  BiCaretRight,
  BiCaretUp,
  BiCheck,
  BiDollarCircle,
  BiMenu,
  BiMoviePlay,
  BiSolidBot,
  BiSolidConversation,
  BiSolidHome,
  BiSolidZap,
  BiX,
} from 'react-icons/bi';
import { FaShop } from 'react-icons/fa6';
import { Locale } from '../../../i18n-config';
import HumanIcon from '../common/icons/Human.icon';
import LanguageChangeDropdown, { LanguageMapper, lngFlagMapper } from '../common/LanguageChangeDropdown';

type Props = {
  theme?: string;
};

type FeatureProps = {
  feature: {
    menu: Array<{
      title: string;
      description: string;
      icon: React.ReactNode;
      link: string;
    }>;
    blogs: Array<{
      title: string;
      description: string;
      imageUrl: string;
      link: string;
    }>;
    viewAllPost: {
      title: string;
      link: string;
    };
  };
  bussinesPartner: {
    menu: Array<{
      title: string;
      description: string;
      icon: React.ReactNode;
      link: string;
    }>;
  };
};

export const NAVIGATION_CONSTANTS = {
  SCROLL_THRESHOLD: 10,
  Z_INDEX: 50,
  MAX_Z_INDEX: 9999999,
};

export default function SiteNavbarComponent({ theme }: Props) {
  const params = useParams();
  const pathname = usePathname();

  const lang = Array.isArray(params?.lang) ? params.lang[0] : params?.lang || 'en-US';

  const { t, i18n } = useTranslation(lang as Locale);

  const { Links } = useLinks();

  const feature = useMemo(() => {
    return {
      menu: [
        {
          title: t('HeaderMenu/features/aiChatbots/title'),
          description: t('HeaderMenu/features/aiChatbots/description'),
          icon: <BiSolidBot className="tw-w-6 tw-h-6 tw-shrink-0 tw-text-gray-500" />,
          link: Links.AIChatbot.href,
        },
        {
          title: t('HeaderMenu/features/chatbotsWithFlow/title'),
          description: t('HeaderMenu/features/chatbotsWithFlow/description'),
          icon: <BiSolidConversation className="tw-w-6 tw-h-6 tw-shrink-0 tw-text-gray-500" />,
          link: Links.ScenarioChatbot.href,
        },
        {
          title: t('HeaderMenu/features/videoPopUp/title'),
          description: t('HeaderMenu/features/videoPopUp/description'),
          icon: <BiMoviePlay className="tw-w-6 tw-h-6 tw-shrink-0 tw-text-gray-500" />,
          link: Links.VideoBot.href,
        },
        {
          title: t('HeaderMenu/features/aiClone/title'),
          description: t('HeaderMenu/features/aiClone/description'),
          icon: <HumanIcon className={'tw-w-6 tw-h-6 tw-shrink-0 tw-text-gray-500'} />,
          link: Links.HumanClone.href,
        },
      ],
      blogs: [
        {
          title: t('HeaderMenu/blog/posts/post1/title'),
          description: t('HeaderMenu/blog/posts/post1/description'),
          imageUrl:
            'https://cdn.1cdn.app/application/LOUISEBOT/24081711380352_Why_Choose_a_Video_Chatbot_Over_a_Text-Based_Chatbot.png',
          link: Links.TextVSVideo.href,
        },
        {
          title: t('HeaderMenu/blog/posts/post2/title'),
          description: t('HeaderMenu/blog/posts/post2/description'),
          imageUrl: 'https://cdn.1cdn.app/application/LOUISEBOT/24080506275418_stripe.png',
          link: Links.IntegrationStripe.href,
        },
      ],
      viewAllPost: {
        title: t('HeaderMenu/blog/viewAll'),
        link: '',
      },
    };
  }, [
    Links.AIChatbot.href,
    Links.HumanClone.href,
    Links.IntegrationStripe.href,
    Links.ScenarioChatbot.href,
    Links.TextVSVideo.href,
    Links.VideoBot.href,
    t,
  ]);

  const businessParnet = useMemo(() => {
    return {
      menu: [
        {
          title: t('HeaderMenu/businessPartner/Agencies/title'),
          description: t('HeaderMenu/businessPartner/Agencies/description'),
          icon: <FaShop className="tw-w-6 tw-h-6 tw-shrink-0 tw-text-gray-500" />,
          link: Links.partnership.href,
        },
        {
          title: t('HeaderMenu/businessPartner/AffiliateProgram/title'),
          description: t('HeaderMenu/businessPartner/AffiliateProgram/description'),
          icon: <BiMoviePlay className="tw-w-6 tw-h-6 tw-shrink-0 tw-text-gray-500" />,
          link: Links.BecomeAnAffiliate.href,
        },
      ],
    };
  }, [Links.BecomeAnAffiliate.href, Links.partnership.href, t]);

  const [show, setShow] = useState(true);

  // useEffect(() => {
  //   // Ensure the translation has finished loading
  //   i18n.changeLanguage(i18n.language);
  // }, [i18n, lang]);

  if (pathname?.includes('chat/')) return null;

  return (
    <React.Fragment>
      {/* Offer Banner */}
      {show && (
        <div className="offer-alert tw-relative tw-max-h-[30px] tw-flex tw-items-center tw-w-full tw-justify-center tw-bg-[#2562eb]">
          <Link
            passHref
            href={Links.register.href}
            className="tw-py-4 tw-text-center tw-flex tw-items-center tw-gap-2 tw-text-[#EEF1FD] tw-text-sm hover:tw-text-white"
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.26044 23.0854L21.6397 2.39572L19.7387 0.798828L17.0989 3.94141H4C2.897 3.94141 2 4.83841 2 5.94141V17.9414C2 18.9356 2.7288 19.7625 3.68035 19.9159L2.35938 21.4885L4.26044 23.0854ZM5.33892 17.9414H4V11.9414H10.3789L5.33892 17.9414ZM13.7389 7.94141L15.4189 5.94141H4V7.94141H13.7389ZM20 7.94141H18.569L15.2646 11.9414H20.001L20.002 17.9414H10.3081L8.65592 19.9414H20C21.103 19.9414 22 19.0444 22 17.9414V5.94141C22 5.38304 21.7701 4.87746 21.4 4.51432L20 6.2091V7.94141Z"
                fill="#ffffff"
              />
            </svg>
            {t('Layout/Banner/key1')}
          </Link>
          <BiX
            onClick={() => setShow(false)}
            size={20}
            className="tw-absolute tw-right-2 tw-cursor-pointer"
            color="#697177"
          />
        </div>
      )}

      {/* Desktop or Mobile Menu */}
      <DesktopMenu feature={feature} bussinesPartner={businessParnet} />
      <MobileMenu feature={feature} bussinesPartner={businessParnet} />
    </React.Fragment>
  );
}

const MobileMenu = ({ feature, bussinesPartner }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [currentMenu, setCurrentMenu] = useState('main'); // 'main', 'features', 'businessPartner', 'language'
  const pathname = usePathname();
  const router = useRouter();
  const { Links } = useLinks();
  const params = useParams();
  const lang = params?.lang;
  const { t } = useTranslation(lang as Locale);

  const ref = useRef(null);

  useClickOutside(ref, () => {
    setShowMenu(false);
    setCurrentMenu('main');
  });

  const selectHandler = (locale: string) => {
    if (!pathname) return `/${locale}`;
    const segments = pathname.split('/');
    segments[1] = locale;
    return segments.join('/');
  };

  const handleClick = (selectionHandler: any) => {
    const newPath = selectionHandler();
    if (newPath) {
      router.push(newPath);
    }
    setShowMenu(true);
    setCurrentMenu('main');
  };

  // Manage menu navigation
  const toggleMenu = () => {
    setCurrentMenu('main');
    setShowMenu(!showMenu);
  };

  const goToMainMenu = () => setCurrentMenu('main');
  const goToFeaturesMenu = () => setCurrentMenu('features');
  const goToBusinessPartnerMenu = () => setCurrentMenu('businessPartner');
  const goToLanguageMenu = () => setCurrentMenu('language');

  const displayLanguageName = () => {
    if (!pathname) return LanguageMapper['en-US'];
    const segments = pathname.split('/');
    return LanguageMapper[segments[1]];
  };

  return (
    <div
      ref={ref}
      style={{ zIndex: showMenu ? NAVIGATION_CONSTANTS.MAX_Z_INDEX : NAVIGATION_CONSTANTS.Z_INDEX }}
      className={`
       ${
         showMenu ? 'tw-fixed tw-inset-0 tw-bg-[#192433] tw-h-screen tw-flex-col' : 'tw-sticky tw-top-0'
       } lg:tw-hidden tw-flex tw-w-full`}
    >
      {/* Header */}
      <div className="tw-flex tw-items-center tw-justify-between tw-p-4 tw-bg-[#111827] tw-w-full">
        <Link className="tw-flex tw-items-center tw-gap-2" passHref href={Links.home.href}>
          <img
            src="https://cdn.1cdn.app/application/LOUISEBOT/23091707385795_Logo_colorful.svg"
            alt="logo"
            className="tw-w-8 tw-h-8"
            width={40}
            height={40}
          />
          <span className="tw-text-[#4167DA] tw-text-lg tw-font-extrabold tw-font-['Lexend']">LouiseBot</span>
        </Link>
        <button onClick={toggleMenu} className="tw-text-white tw-text-lg tw-font-['Lexend'] tw-bg-transparent p-2">
          {showMenu ? <BiX size={24} className="tw-text-white" /> : <BiMenu size={24} className="tw-text-white" />}
        </button>
      </div>

      {/* Dropdown Menu */}
      {showMenu && (
        <div className="tw-bg-[#111827] tw-w-full tw-h-screen tw-flex tw-flex-col tw-px-4 tw-overflow-y-auto">
          {/* Main Menu */}
          {currentMenu === 'main' && (
            <>
              <div className="tw-flex tw-flex-col tw-gap-6 tw-mt-4">
                {/* Link to main page */}
                <Link
                  passHref
                  href={Links.home.href}
                  className="tw-flex tw-items-center tw-justify-between tw-gap-1 tw-text-white tw-py-1 tw-text-base tw-font-normal tw-font-['Lexend']"
                  onClick={() => setShowMenu(false)}
                >
                  <div className="tw-flex tw-items-center tw-gap-2">
                    <BiSolidHome className="tw-w-6 tw-h-6 tw-text-gray-500" />
                    {t('SiteNavbar/l-05')}
                  </div>
                </Link>

                {/* Link to features */}
                <button
                  onClick={goToFeaturesMenu}
                  className="tw-flex tw-items-center tw-justify-between tw-gap-1 tw-text-white tw-py-1 tw-text-base tw-font-normal tw-font-['Lexend'] tw-bg-transparent"
                >
                  <div className="tw-flex tw-items-center tw-gap-2">
                    <BiSolidZap className="tw-w-6 tw-h-6 tw-text-gray-500" />
                    {t('SiteNavbar/l-10')}
                  </div>
                  <BiCaretRight className="tw-w-4 tw-h-4  tw-text-white" />
                </button>

                {/* Link to plans */}
                <Link
                  passHref
                  href={Links.pricing.href}
                  className="tw-flex tw-items-center tw-justify-between tw-gap-1 tw-text-white tw-py-1 tw-text-base tw-font-normal tw-font-['Lexend']"
                  onClick={() => setShowMenu(false)}
                >
                  <div className="tw-flex tw-items-center tw-gap-2">
                    <BiDollarCircle className="tw-w-6 tw-h-6 tw-text-gray-500" />
                    {t('SiteNavbar/l-03')}
                  </div>
                </Link>

                {/* Link to business partners */}
                <button
                  onClick={goToBusinessPartnerMenu}
                  className="tw-flex tw-items-center tw-justify-between tw-gap-1 tw-text-white tw-py-1 tw-text-base tw-font-normal tw-font-['Lexend'] tw-bg-transparent"
                >
                  <div className="tw-flex tw-items-center tw-gap-2">
                    <BusinessPartnerPlanIcon className="tw-w-6 tw-h-6 tw-text-gray-500" />
                    {t('SiteNavbar/l-15')}
                  </div>
                  <BiCaretRight className="tw-w-4 tw-h-4  tw-text-white" />
                </button>

                {/* Line for language selection */}
                <button
                  onClick={goToLanguageMenu}
                  className="tw-flex tw-items-center tw-justify-between tw-gap-1 tw-text-white tw-py-1 tw-text-base tw-font-normal tw-font-['Lexend'] tw-bg-transparent"
                >
                  <div className="tw-flex tw-items-center tw-gap-2">
                    <Image
                      src={lngFlagMapper[lang as string]}
                      alt={lang as string}
                      width={28}
                      height={28}
                      className="tw-rounded-full"
                      title={(lang as string) + ' flag'}
                    />
                    <div className="tw-text-white tw-text-base tw-font-normal tw-font-['Lexend'] tw-leading-normal">
                      {displayLanguageName()}
                    </div>
                  </div>
                  <BiCaretRight className="tw-w-4 tw-h-4  tw-text-white" />
                </button>
              </div>

              {/* Two button section */}
              <div className="tw-grid tw-grid-cols-2 tw-gap-4 tw-mt-6 tw-py-3">
                <Link
                  passHref
                  href={Links.login.href}
                  className="tw-w-full tw-self-stretch tw-px-4 tw-py-3 tw-bg-gray-700 tw-rounded-full tw-text-white tw-text-sm tw-font-normal tw-font-['Lexend'] tw-text-center"
                  onClick={() => setShowMenu(false)}
                >
                  {t('SiteNavbar/l-01')}
                </Link>
                <Link
                  passHref
                  href={Links.register.href}
                  className="tw-w-full tw-self-stretch tw-px-4 tw-py-3 tw-bg-blue-600 tw-rounded-full tw-text-white tw-text-sm tw-font-normal tw-font-['Lexend'] tw-text-center"
                  onClick={() => setShowMenu(false)}
                >
                  {t('SiteNavbar/l-04')}
                </Link>
              </div>
            </>
          )}

          {/* Features Menu */}
          {currentMenu === 'features' && (
            <React.Fragment>
              <button
                onClick={goToMainMenu}
                className="tw-flex tw-items-center tw-gap-2 tw-text-white tw-pt-6 tw-px-4 tw-text-sm tw-font-normal tw-font-['Lexend'] tw-bg-transparent"
              >
                <BiArrowBack className="tw-w-6 tw-h-6 tw-relative tw-text-white" />
                <div className="tw-text-white tw-text-base tw-font-normal tw-font-['Lexend'] tw-leading-normal">
                  {t('HeaderMenu/BacktoMainMenu')}
                </div>
              </button>
              <div className="tw-grid tw-grid-cols-1 tw-gap-4 tw-mt-10">
                {feature.menu.map((item, index) => (
                  <Link
                    passHref
                    href={item.link}
                    key={index}
                    className="tw-flex tw-items-start tw-gap-4 tw-bg-[#111827] tw-p-1  tw-rounded-md"
                    onClick={() => setShowMenu(false)}
                  >
                    {item.icon}
                    <div className="tw-flex tw-flex-col">
                      <div className="tw-text-white tw-text-base tw-font-medium tw-font-['Inter']">{item.title}</div>
                      <div className="tw-text-gray-400 tw-text-sm tw-font-normal tw-font-['Inter'] tw-mt-1">
                        {item.description}
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
              <div className="tw-pt-8  tw-pb-10  tw-mt-10 tw-flex-col tw-justify-start tw-items-start tw-gap-6 tw-inline-flex">
                <div className="tw-self-stretch tw-justify-start tw-items-center tw-inline-flex">
                  <div className="tw-grow tw-shrink tw-basis-0 tw-text-gray-400 tw-text-sm tw-font-medium tw-font-['Inter'] tw-uppercase tw-leading-tight tw-tracking-tight">
                    {t('SiteNavbar/l-16')}
                  </div>
                </div>
                {feature.blogs.map((item, index) => (
                  <Link
                    passHref
                    key={index}
                    href={item.link}
                    className="tw-flex tw-items-start tw-gap-4 tw-bg-[#111827] tw-p-4  tw-rounded-md"
                    onClick={() => setShowMenu(false)}
                  >
                    <div className="tw-p-3  tw-flex-col tw-justify-start tw-items-start tw-gap-1 tw-inline-flex">
                      <Image src={item.imageUrl} alt="louisebot_logo" title="louisebot_logo" width={772} height={424} />
                      <div className="tw-mt-2 tw-self-stretch tw-text-white tw-text-base tw-font-medium tw-font-['Inter'] tw-leading-normal">
                        {item.title}
                      </div>
                      <div className="tw-self-stretch tw-text-gray-400 tw-text-sm tw-font-normal tw-font-['Inter'] tw-leading-tight">
                        {item.description}
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </React.Fragment>
          )}

          {/* Business Partner Menu */}
          {currentMenu === 'businessPartner' && (
            <React.Fragment>
              <button
                onClick={goToMainMenu}
                className="tw-flex tw-items-center tw-gap-2 tw-text-white tw-pt-6 tw-px-4 tw-text-sm tw-font-normal tw-font-['Lexend'] tw-bg-transparent"
              >
                <BiArrowBack className="tw-w-6 tw-h-6 tw-relative tw-text-white" />
                <div className="tw-text-white tw-text-base tw-font-normal tw-font-['Lexend'] tw-leading-normal">
                  {t('HeaderMenu/BacktoMainMenu')}
                </div>
              </button>
              <div className="tw-grid tw-grid-cols-1 tw-gap-4 tw-mt-4">
                {bussinesPartner.menu.map((item, index) => (
                  <Link
                    passHref
                    href={item.link}
                    key={index}
                    className="tw-flex tw-items-start tw-gap-4 tw-bg-[#111827] tw-p-4 tw-rounded-md"
                    onClick={() => setShowMenu(false)}
                  >
                    {item.icon}
                    <div className="tw-flex tw-flex-col">
                      <div className="tw-text-white tw-text-base tw-font-medium tw-font-['Inter']">{item.title}</div>
                      <div className="tw-text-gray-400 tw-text-sm tw-font-normal tw-font-['Inter'] tw-mt-1">
                        {item.description}
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </React.Fragment>
          )}

          {currentMenu === 'language' && (
            <React.Fragment>
              <div className="tw-h-[calc(100vh-60px)] tw-w-full tw-shadow-lg tw-py-6 tw-px-4 tw-pb-8 tw-bg-gray-900 tw-flex-col tw-justify-start tw-items-start tw-gap-10 tw-inline-flex">
                <div className="tw-self-stretch tw-flex-col tw-justify-center tw-items-start tw-gap-5 tw-flex">
                  <div
                    onClick={goToMainMenu}
                    className="tw-flex tw-items-center tw-gap-2 tw-text-white tw-py-2 tw-px-4 tw-text-base tw-font-normal tw-font-['Lexend'] tw-bg-transparent"
                  >
                    <BiArrowBack className="tw-w-6 tw-h-6 tw-text-white" />
                    {t('HeaderMenu/BacktoMainMenu')}
                  </div>

                  <div className="tw-flex tw-flex-col tw-gap-4 tw-w-full tw-mt-4">
                    <div
                      onClick={() => handleClick(() => selectHandler('en-US'))}
                      className="tw-flex tw-justify-between tw-items-center tw-px-4 tw-py-2.5 tw-bg-[#111827] tw-rounded-lg"
                    >
                      <div className="tw-flex tw-items-center tw-gap-3">
                        <Image
                          src={lngFlagMapper['en-US']}
                          alt="English"
                          width={28}
                          height={28}
                          className="tw-rounded-full"
                        />
                        <span className="tw-text-white tw-text-base tw-font-normal tw-font-['Lexend']">English</span>
                      </div>
                      {lang === 'en-US' && <BiCheck className="tw-w-6 tw-h-6 tw-text-blue-500" />}
                    </div>
                    <div
                      onClick={() => handleClick(() => selectHandler('fr-FR'))}
                      className="tw-flex tw-justify-between tw-items-center tw-px-4 tw-py-2.5 tw-bg-[#111827] tw-rounded-lg"
                    >
                      <div className="tw-flex tw-items-center tw-gap-3">
                        <Image
                          src={lngFlagMapper['fr-FR']}
                          alt="French"
                          width={28}
                          height={28}
                          className="tw-rounded-full"
                        />
                        <span className="tw-text-white tw-text-base tw-font-normal tw-font-['Lexend']">Français</span>
                      </div>
                      {lang === 'fr-FR' && <BiCheck className="tw-w-6 tw-h-6 tw-text-blue-500" />}
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      )}
    </div>
  );
};

const DesktopMenu = ({ feature, bussinesPartner }: FeatureProps) => {
  const [scrolled, setScrolled] = useState(false);
  const params = useParams();
  const lang = params?.lang;
  const { t } = useTranslation((lang as Locale) || 'defaultLocale');

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 1); // Change threshold as needed
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const { Links } = useLinks();

  const [showFeatures, setShowFeatures] = useState(false);
  const [showBusinessPartner, setShowBusinessPartner] = useState(false);

  const ref = useRef(null);

  useClickOutside(ref, () => {
    onClose();
  });

  const onClose = () => {
    setShowFeatures(false);
    setShowBusinessPartner(false);
  };

  return (
    <div
      ref={ref}
      style={{ zIndex: NAVIGATION_CONSTANTS.Z_INDEX }}
      className={`${
        scrolled ? 'tw-sticky top-0' : ''
      }  tw-bg-[#192433] tw-w-full  tw-flex-col tw-justify-center tw-items-center tw-relative lg:tw-flex tw-hidden`}
    >
      <div className="tw-w-full tw-p-6 tw-bg-[#192433] tw-justify-between tw-items-center tw-inline-flex">
        <div className="tw-justify-start tw-items-center tw-gap-8 tw-flex">
          <div className="tw-justify-start tw-items-center tw-gap-10 tw-flex">
            <Link passHref={true} href={Links.home.href} className="tw-justify-start tw-items-center tw-gap-1 tw-flex">
              <Image
                src="https://cdn.1cdn.app/application/LOUISEBOT/23091707385795_Logo_colorful.svg"
                alt="logo"
                title="logo"
                width={40}
                height={40}
              />
              <div className="tw-text-[#4167DA] tw-text-lg tw-font-extrabold tw-font-['Lexend'] tw-leading-7">
                LouiseBot
              </div>
            </Link>
          </div>
          <div className="tw-justify-start tw-items-center tw-gap-8 tw-flex">
            <Link
              passHref={true}
              href={Links.home.href}
              className="tw-text-white tw-text-sm tw-font-normal tw-font-['Lexend'] tw-leading-tight"
            >
              {Links.home.name}
            </Link>
            <div
              onClick={() => {
                setShowBusinessPartner(false);
                setShowFeatures((prev) => !prev);
              }}
              className="tw-cursor-pointer tw-justify-center tw-items-center tw-gap-1 tw-flex tw-relative"
            >
              <div className="tw-text-white tw-text-sm tw-font-normal tw-font-['Lexend'] tw-leading-tight">
                {t('SiteNavbar/l-10')}
              </div>
              {!showFeatures && <BiCaretDown className="tw-text-white tw-w-4 tw-h-4 tw-relative" />}
              {showFeatures && <BiCaretUp className="tw-text-white tw-w-4 tw-h-4 tw-relative" />}
            </div>
            <Link
              passHref={true}
              onClick={() => {
                onClose();
              }}
              href={Links.pricing.href}
              className="tw-text-white tw-text-sm tw-font-normal tw-font-['Lexend'] tw-leading-tight"
            >
              {Links.pricing.name}
            </Link>
            <div
              className="tw-cursor-pointer tw-justify-center tw-items-center tw-gap-1 tw-flex tw-relative"
              onClick={() => {
                setShowFeatures(false);
                setShowBusinessPartner((prev) => !prev);
              }}
            >
              <div className="tw-text-white tw-text-sm tw-font-normal tw-font-['Lexend'] tw-leading-tight">
                {t('SiteNavbar/l-15')}
              </div>
              {!showBusinessPartner && <BiCaretDown className="tw-text-white tw-w-4 tw-h-4 tw-relative" />}
              {showBusinessPartner && <BiCaretUp className="tw-text-white tw-w-4 tw-h-4 tw-relative" />}
            </div>
          </div>
        </div>
        <div className="tw-h-9 tw-justify-end tw-items-center tw-gap-3 tw-flex">
          <div className="tw-pl-1 tw-pr-1.5 tw-py-1 tw-bg-[#192433] tw-rounded-full tw-justify-start tw-items-center tw-gap-0.5 tw-flex">
            <LanguageChangeDropdown id="language-dropdown-navbar" />
          </div>
          <Link
            passHref={true}
            onClick={() => {
              onClose();
            }}
            href={Links.login.href}
            className="tw-px-4 tw-py-2 tw-bg-gray-700 tw-rounded-full tw-justify-center tw-items-center tw-flex"
          >
            <div className="tw-text-white tw-text-sm tw-font-normal tw-font-['Lexend'] tw-leading-tight">
              {Links.login.name}
            </div>
          </Link>
          <Link
            passHref={true}
            onClick={() => {
              onClose();
            }}
            href={Links.register.href}
            className="tw-px-4 tw-py-2 tw-bg-blue-600 tw-rounded-full tw-justify-center tw-items-center tw-flex"
          >
            <div className="tw-text-white tw-text-sm tw-font-normal tw-font-['Lexend'] tw-leading-tight">
              {Links.register.name}
            </div>
          </Link>
        </div>
      </div>

      {/* Features Dropdown */}
      {showFeatures && (
        <div className="tw-w-full tw-absolute tw-top-20 tw-bg-gray-900 tw-z-50 tw-p-6">
          <div className="tw-container tw-grid tw-grid-cols-2 tw-gap-12 tw-m-auto">
            <div className="tw-flex tw-flex-col tw-gap-6">
              {feature.menu.map((item, index) => (
                <Link
                  passHref={true}
                  href={item.link}
                  key={index}
                  className="tw-flex tw-items-start tw-gap-4 tw-p-3 tw-rounded-lg"
                  onClick={onClose}
                >
                  {item.icon}
                  <div>
                    <div className="tw-text-white tw-text-base tw-font-medium tw-font-['Inter']">{item.title}</div>
                    <div className="tw-text-gray-400 tw-text-sm">{item.description}</div>
                  </div>
                </Link>
              ))}
            </div>
            <div className="tw-flex tw-flex-col tw-gap-6">
              <div className="tw-uppercase tw-text-gray-400 tw-text-sm tw-font-medium">{t('SiteNavbar/l-16')}</div>
              <div className="tw-flex tw-flex-col tw-gap-4">
                {feature.blogs.map((item, index) => (
                  <Link
                    passHref={true}
                    href={item.link}
                    key={index}
                    className="tw-flex tw-items-start tw-gap-4 tw-p-3 tw-rounded-lg"
                    onClick={onClose}
                  >
                    <img
                      src={item.imageUrl}
                      alt={item.title}
                      className="tw-w-32 tw-h-20 tw-rounded-md tw-object-cover"
                    />
                    <div>
                      <div className="tw-text-white tw-text-base">{item.title}</div>
                      <div className="tw-text-gray-400 tw-text-sm">{item.description}</div>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Business Partner Dropdown */}
      {showBusinessPartner && (
        <div className="tw-w-full tw-absolute tw-top-20 tw-bg-gray-900 tw-z-50 tw-p-6">
          <div className="tw-container tw-grid tw-grid-cols-2 tw-gap-12 tw-m-auto">
            <div className="tw-flex tw-flex-col tw-gap-6">
              {bussinesPartner.menu.map((item, index) => (
                <Link
                  passHref={true}
                  href={item.link}
                  key={index}
                  className="tw-flex tw-items-start tw-gap-4 tw-p-3 tw-rounded-lg"
                  onClick={onClose}
                >
                  {item.icon}
                  <div>
                    <div className="tw-text-white tw-text-base tw-font-medium">{item.title}</div>
                    <div className="tw-text-gray-400 tw-text-sm">{item.description}</div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

MobileMenu.displayName = 'MobileMenu';
DesktopMenu.displayName = 'DesktopMenu';
