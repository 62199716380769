'use client';

import { useParams, usePathname, useRouter } from 'next/navigation';
import React from 'react';
import { Dropdown, DropdownProps } from 'react-bootstrap';
import { i18n as i18nConfig, Locale } from '../../../i18n-config';
import { useTranslation } from '@/i18n/client';
import Image from 'next/image';
import { BiCaretDown } from 'react-icons/bi';
import { setCookie } from 'cookies-next';
import i18next from 'i18next';

export const LanguageMapper = {
  'en-US': 'English',
  en: 'English',
  'fr-FR': 'Français',
  fr: 'Français',
} as { [key: string]: string };

export const lngFlagMapper = {
  'en-US': 'https://cdn.1cdn.app/application/UMD/24041507435389_US.png',
  en: 'https://cdn.1cdn.app/application/UMD/24041507435389_US.png',
  'fr-FR': 'https://cdn.1cdn.app/application/UMD/24041507435387_France.png',
  fr: 'https://cdn.1cdn.app/application/UMD/24041507435387_France.png',
} as { [key: string]: string };

const LanguageChangeDropdown = ({
  placement = 'bottom',
  id,
}: {
  placement?: DropdownProps['placement'] | undefined;
  id: string;
}) => {
  const router = useRouter();

  const params = useParams();
  const pathName = usePathname();
  const lang = params?.lang;
  const { t } = useTranslation((lang as Locale) || 'defaultLocale');

  const selectHandler = (locale: string) => {
    if (!pathName) return '/en-US';

    const segments = pathName.split('/');
    segments[1] = locale;

    i18next.changeLanguage(locale);

    setCookie('NEXT_LOCALE', locale, { expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365) });

    return segments.join('/');
  };

  const handleClick = (url: string) => {
    router.push(url);
  };

  // eslint-disable-next-line react/display-name
  const CustomToggle = React.forwardRef(
    ({ children, onClick }: { children: React.ReactNode; onClick: (e: any) => void }, ref: any) => (
      <div
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
        className="lngSelectorWrapper tw-p-2 tw-rounded-full tw-bg-gray-800 tw-relative tw-flex tw-items-center"
      >
        <Image
          src={lngFlagMapper[lang as string]}
          alt={lang as string}
          width={28}
          height={28}
          className="tw-rounded-full"
          title={(lang as string) + ' flag'}
        />
        <BiCaretDown size={16} className="tw-ml-2" />
      </div>
    )
  );

  return (
    <Dropdown placement={placement}>
      <Dropdown.Toggle as={CustomToggle} id={id} />
      <Dropdown.Menu>
        {i18nConfig.locales.map((l) => (
          <Dropdown.Item key={l} as={'span'}>
            <span
              onClick={() => {
                handleClick(selectHandler(l));
              }}
              className="d-block tw-cursor-pointer"
            >
              {LanguageMapper[l]}
            </span>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LanguageChangeDropdown;
