'use client';

import { useEffect } from 'react';
import { usePathname } from 'next/navigation';

const RouteChangeScript = ({ code }: { code: string }) => {
  const pathname = usePathname();

  useEffect(() => {
    let script: HTMLScriptElement | null = null;

    const removeIframesWithIdPrefix = (prefix: string) => {
      return new Promise<void>((resolve, reject) => {
        try {
          const scripts = document.querySelectorAll('iframe');
          scripts.forEach((scriptElement) => {
            if (scriptElement.id && scriptElement.id.startsWith(prefix)) {
              scriptElement.remove();
            }
          });
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    };

    removeIframesWithIdPrefix('ps_w_')
      .then(() => {
        script = document.createElement('script');
        script.innerHTML = `
          !(function () { function g() {
            var script = document.createElement('script'), code = '${code}' ,url = 'https://cdn.1cdn.app/application/LB/23082315255659_LB_widget.js', l = localStorage.setItem('ps_widget' , code);
            (script.type = 'text/javascript'),(script.async = !0),(script.src = url);
            document.getElementsByTagName('head')[0].appendChild(script);          
          }

          document.readyState === 'complete' ? g() : window.attachEvent ? window.attachEvent('onload', g)
          : window.addEventListener('load', g, !1);
          })();        
        `;
        script.id = 'louiseBot_widget';
        document.head.appendChild(script);
      })
      .catch((error) => {
        console.error('Error removing scripts:', error);
      });

    return () => {
      if (script && script.parentNode) {
        script.parentNode.removeChild(script);
      }
    };
  }, [pathname, code]);

  return null;
};

export default RouteChangeScript;
